import styled from '@emotion/styled';
import type { FC } from 'react';
import * as assetsConfig from '../../config/assets';

interface Props {
  videoURL: string;
}

const DeviceFrame: FC<Props> = ({ videoURL }) => (
  <Wrapper>
    <Frame />
    <Content>
      <video controls={false} autoPlay muted loop playsInline>
        <source src={videoURL} type="video/mp4" />
      </video>
    </Content>
  </Wrapper>
);

export default DeviceFrame;

const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${assetsConfig.prefix}device-frame.png) no-repeat center center;
  background-size: contain;
  z-index: 5;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  height: 614px;
  width: 366px;
`;

const Content = styled.div`
  z-index: 1;
  position: absolute;
  top: 53px;
  left: 65px;
  right: 67px;
  bottom: 55px;
  background: #ccc;
  overflow: hidden;
  video {
    display: block;
    height: 100%;
  }
`;
