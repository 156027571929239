import styled from '@emotion/styled';
import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import type { ReactNode, FC } from 'react';

type Placement = 'x' | 'y';

interface Props {
  children: ReactNode;
  placement?: Placement;
  paddingY?: string;
  paddingX?: string;
  size?: 'xs' | 'md';
}

const BurstHead: FC<Props> = ({ children, placement = 'x', paddingY = '40px', paddingX = '0', size = 'md' }) => {
  const typographyStyled = useMemo(() => {
    if (size === 'md') return { fontSize: { xs: '18px', md: '24px' } };
    return { fontSize: { xs: '10px', md: '14px' } };
  }, [size]);
  return (
    <Wrapper
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
      placement={placement}
      paddingY={paddingY}
      paddingX={paddingX}
      size={size}
    >
      <Typography variant="h6" sx={typographyStyled}>
        <span>{children}</span>
      </Typography>
    </Wrapper>
  );
};

export default BurstHead;

const svgMd = `
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0H10L22 24L5 0Z" fill="#FFD900"/>
    <path d="M0 9H4.11765L14 24L0 9Z" fill="#3E92CC"/>
  </svg>
`
  .replace(/\n\s*/, '')
  .trim();

const svgXs = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path d="M3.63672 0H7.27308L16.0004 17L3.63672 0Z" fill="#FFD900"/>
    <path d="M0 6.375H2.99465L10.1818 17L0 6.375Z" fill="#3E92CC"/>
  </svg>
`
  .replace(/\n\s*/, '')
  .trim();

const Wrapper = styled(Box)<{ placement: Placement; paddingY: string; paddingX: string; size: 'md' | 'xs' }>`
  margin: 0 auto;
  position: relative;
  h6 {
    > span {
      display: block;
      text-align: center;
      padding: ${({ paddingY, paddingX }) => `${paddingY} ${paddingX}`};
    }
    display: flex;
    align-items: stretch;
    font-weight: 700;
    justify-content: center;
    position: relative;
    &:before,
    &:after {
      width: 45px;
      content: '';
      display: block;
      background: url('data:image/svg+xml;charset=UTF-8,${({ size }) =>
          size === 'md' ? encodeURIComponent(svgMd) : encodeURIComponent(svgXs)}')
        no-repeat;
      background-position: ${({ placement }) => (placement === 'x' ? 'center center' : 'top center')};
    }
    &:after {
      transform: ${({ placement }) => (placement === 'x' ? 'rotateY(180deg)' : 'rotate(180deg)')};
    }
  }
`;
