import { createContext, useContext } from 'react';
import type { CurriculumObject } from 'src/entities';

const CurriculumContext = createContext<CurriculumObject | undefined>(undefined);

export const useCurriculumContext = () => {
  return useContext(CurriculumContext);
};

export const CurriculumProvider = ({
  curriculum,
  children,
}: {
  curriculum: CurriculumObject;
  children: React.ReactNode;
}) => <CurriculumContext.Provider value={curriculum}>{children}</CurriculumContext.Provider>;
