import type { FC } from 'react';
import { Inbound, OTA, Other, Salon, Food, Point, QR, SNS, Map } from '../../graphics';

export type IconName = 'inbound' | 'ota' | 'other' | 'salon' | 'food' | 'point' | 'qr' | 'sns' | 'map';

interface Props {
  name: IconName;
}

const CurriculumIcon: FC<Props> = ({ name }) => {
  switch (name) {
    case 'inbound':
      return <Inbound />;
    case 'ota':
      return <OTA />;
    case 'other':
      return <Other />;
    case 'salon':
      return <Salon />;
    case 'food':
      return <Food />;
    case 'point':
      return <Point />;
    case 'qr':
      return <QR />;
    case 'sns':
      return <SNS />;
    case 'map':
      return <Map />;
    default:
      return null;
  }
};

export default CurriculumIcon;
