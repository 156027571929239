import { Box, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import type { UserObject } from '../../entities';
import type { SectionAchievePoint } from '../../types';
import MemberRegistrationPrompt from '../global/MemberRegistrationPrompt';
import { Close } from '../../graphics';
import { useCurriculumContext } from 'src/contexts/CurriculumContext';
import { isMemberRegistrationIncomplete } from 'src/helpers/userRegistrationHelpers';
import { useSignupDialogContext } from 'src/contexts/SignupDialogContext';

interface Props {
  lessonId: string | null;
  user: UserObject | null;
  onClose?: () => void;
  onNext?: (lessonId: string) => void;
  handleAchieveDialog?: (handle: boolean, nextLesson: string, achievePoint: string) => void;
  sectionAchievePoint?: SectionAchievePoint;
  sectionInbound?: boolean;
}

const DynamicLessonChat = dynamic(() => import('./LessonChat').then((mod) => mod.default), { ssr: false });

const LessonChatDialog: React.FC<Props> = ({
  lessonId,
  onClose,
  user,
  onNext,
  handleAchieveDialog,
  sectionAchievePoint,
  sectionInbound,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setIsOpen] = useState(false);
  const signupDialog = useSignupDialogContext();
  const isLessonIdPresent = lessonId !== null && lessonId !== undefined;

  // NOTE:
  // マイページの教科書の進み具合から開く場合は Provider を使っていないので Context は取得できないが、
  // curriculum の値は非会員向けの isFirstLesson 判定のためのものなのでログイン後は現状必要ない
  const curriculum = useCurriculumContext();
  const isFirstLesson = curriculum?.sections[0]?.lessons[0]?.id === lessonId;

  // 会員登録未完了かつ2ページ目以降の場合は会員登録導線を表示する
  const shouldShowMemberRegistrationPrompt = isMemberRegistrationIncomplete(user) && !isFirstLesson && isLessonIdPresent;

  const handleClose = () => {
    // 会員登録導線を表示する場合は escape キーやダイアログ外をクリックしても閉じないようにする
    !shouldShowMemberRegistrationPrompt && onClose?.();
  };

  const { utm_source, utm_medium } = router.query;
  const landedViaAds = (utm_source === 'google' && utm_medium === 'search') || (utm_source === 'yahoo' && utm_medium === 'search');

  useEffect(() => {
    if (isLessonIdPresent && landedViaAds && shouldShowMemberRegistrationPrompt) {
      signupDialog?.setTitle('2ページ目以降は会員登録が必要です');
      signupDialog?.open(() => {
        onClose?.();
        signupDialog?.setTitle(undefined);
      });
      signupDialog?.showClose();
      return;
    }
    setIsOpen(isLessonIdPresent);
  }, [isLessonIdPresent, landedViaAds, shouldShowMemberRegistrationPrompt]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
    >
      {shouldShowMemberRegistrationPrompt && landedViaAds === false && (
        <>
          <DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>2ページ目以降は会員登録が必要です</Box>
              <Box>
                <IconButton size="small" onClick={onClose} sx={{ ml: 1 }}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ mx: 0, px: 1 }}>
            <MemberRegistrationPrompt user={user} />
          </DialogContent>
        </>
      )}
      {shouldShowMemberRegistrationPrompt === false && (
        <DialogContent sx={{ mx: 0, px: 1 }}>
          <DynamicLessonChat
            lessonId={lessonId || ''}
            user={user}
            debug={router.query.debug === '1'}
            onClose={onClose}
            onNext={onNext}
            handleAchieveDialog={handleAchieveDialog}
            sectionAchievePoint={sectionAchievePoint}
            sectionInbound={sectionInbound}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};
export default LessonChatDialog;
