import styled from '@emotion/styled';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button } from '@mui/material';
import type { FC } from 'react';
import NextLink from 'next/link';
import type { CurriculumObject } from '../../entities';
import type { WhitepaperCarouselItem } from '../articles/WhitepaperCarousel';
import WhitepapersCarousel from '../articles/WhitepaperCarousel';
import type { SeminarCarouselItem } from '../articles/SeminarCarousel';
import SeminarsCarousel from '../articles/SeminarCarousel';
import CurriculumsCarousel from './CurriculumsCarousel';

interface Props {
  curriculums: CurriculumObject[];
  whitepaperItems: WhitepaperCarouselItem[];
  seminarItems: SeminarCarouselItem[];
}

const TopThreeCurriculums: FC<Props> = ({ curriculums, whitepaperItems, seminarItems }) => (
  <Box>
    <Box
      sx={{
        fontSize: { xs: '32px', md: '40px' },
        py: { xs: 0, md: 2 },
        px: 3,
        textAlign: 'center',
        fontWeight: 700,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: { xs: 'block', md: 'contents' } }}>口コミアカデミーの</Box>
      <Box sx={{ display: { xs: 'block', md: 'contents' } }}>３つのカリキュラム</Box>
    </Box>
    <Box my={{ xs: 0, md: 5 }}>
      <CurriculumSection>
        <h3>
          <span className="num">01</span>
          <span className="title">
            <Box sx={{ display: { xs: 'block', md: 'contents' } }}>様々なお店向け</Box>
            <Box sx={{ display: { xs: 'block', md: 'contents' } }}>サービスの教科書</Box>
          </span>
        </h3>
        <Box
          sx={{
            px: { xs: 2, md: 5 },
            pb: { xs: 3, md: 6 },
            color: '#555',
            fontSize: '16px',
            lineHeight: '1.8em',
            textAlign: 'center',
          }}
        >
          <Box sx={{ display: { xs: 'contents', md: 'block' } }}>
            口コミサイトやGoogleマップを使った集客やインバウンド対策、ポイントシステムなど
          </Box>
          <Box sx={{ display: { xs: 'contents', md: 'block' } }}>
            お店の集客に導く様々なサービスについて運営ノウハウを体系立てて教科書にまとめました。
          </Box>
        </Box>
        <CurriculumsCarousel curriculums={curriculums} />
        <Box sx={{ pt: { xs: 0, md: 0.5 }, display: 'flex', justifyContent: 'center' }} mt={{ xs: 4, md: 5 }}>
          <NextLink href="/curriculums" passHref>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              endIcon={<ChevronRight />}
              size="large"
              sx={{
                width: '22.5em',
                height: '4em',
                transition: 'opacity 0.2s ease-in-out',
                ':hover': { opacity: 0.8 },
              }}
            >
              もっと見る
            </Button>
          </NextLink>
        </Box>
      </CurriculumSection>
    </Box>
    <Box my={7}>
      <CurriculumSection>
        <h3>
          <span className="num">02</span>
          <span className="title">
            <Box sx={{ display: { xs: 'block', md: 'contents' } }}>店舗マーケティング</Box>
            <Box sx={{ display: { xs: 'block', md: 'contents' } }}>資料で知識を</Box>
            <Box sx={{ display: { xs: 'block', md: 'contents' } }}>つけよう！</Box>
          </span>
        </h3>
        <Box
          sx={{
            px: { xs: 2, md: 5 },
            pb: 6,
            color: '#555',
            fontSize: '16px',
            lineHeight: '1.8em',
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          <div>あなたのお店を取り巻く環境や分析結果など少し難しい内容もレポートを読んだら一目瞭然！</div>
        </Box>
        <Box sx={{ position: 'relative', height: '100%' }}>
          {/* Boxの下半分を#F5F7FAのbackgroundcolorにする */}
          <Box>
            <WhitepapersCarousel items={whitepaperItems} />
          </Box>
          <Box
            sx={{ pt: { xs: 0, md: 0.5 }, pb: 6, display: 'flex', justifyContent: 'center' }}
            mt={{ xs: 4, sm: 2, md: 0 }}
          >
            <NextLink href="/whitepapers" passHref>
              <Button
                color="secondary"
                variant="contained"
                component="a"
                endIcon={<ChevronRight />}
                size="large"
                sx={{
                  width: '22.5em',
                  height: '4em',
                  transition: 'opacity 0.2s ease-in-out',
                  ':hover': { opacity: 0.8 },
                }}
              >
                もっと見る
              </Button>
            </NextLink>
          </Box>
          <BackGroundBox></BackGroundBox>
        </Box>
      </CurriculumSection>
    </Box>
    <Box my={7}>
      <CurriculumSection>
        <h3>
          <span className="num">03</span>
          <span className="title">オンラインセミナー</span>
        </h3>
        <Box
          sx={{
            px: { xs: 2, md: 5 },
            pb: 6,
            color: '#555',
            fontSize: '16px',
            lineHeight: '1.8em',
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          <Box sx={{ display: { xs: 'contents', md: 'block' } }}>
            口コミアカデミーでは店舗運営や口コミサイトの活用方法など様々な専門家や講師の方をお招きして、
          </Box>
          <Box sx={{ display: { xs: 'contents', md: 'block' } }}>オンラインでのセミナー授業を実施しております。</Box>
        </Box>
        <Box sx={{ position: 'relative', height: '100%' }}>
          {/* Boxの下半分を#F5F7FAのbackgroundcolorにする */}
          <Box>
            <SeminarsCarousel items={seminarItems} />
          </Box>
          <Box
            sx={{ pt: { xs: 0, md: 0.5 }, pb: 6, display: 'flex', justifyContent: 'center' }}
            mt={{ xs: 4, sm: 2, md: 0 }}
          >
            <NextLink href="/seminars" passHref>
              <Button
                color="secondary"
                variant="contained"
                component="a"
                endIcon={<ChevronRight />}
                size="large"
                sx={{
                  width: '22.5em',
                  height: '4em',
                  transition: 'opacity 0.2s ease-in-out',
                  ':hover': { opacity: 0.8 },
                }}
              >
                もっと見る
              </Button>
            </NextLink>
          </Box>
          <BackGroundBox></BackGroundBox>
        </Box>
      </CurriculumSection>
    </Box>
  </Box>
);

export default TopThreeCurriculums;

const BackGroundBox = styled(Box)`
  background-color: #f5f7fa;
  bottom: 0;
  height: 80%;
  width: 100%;
  position: absolute;
  z-index: -1;
`;

const CurriculumSection = styled.div`
  h3 {
    line-height: 32px;
    letter-spacing: 3.2px;
    font-size: 32px;
    @media (max-width: 728px) {
      font-size: 28px;
    }
    @media (max-width: 510px) {
      font-size: 24px;
      margin-bottom: 16px;
    }
    .num {
      font-size: 40px;
      color: #ffd900;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      text-align: center;
      display: block;
      padding-bottom: 24px;
    }
    .title {
      display: block;
      clear: both;
      padding: 0 40px;
      text-align: center;
      @media (max-width: 728px) {
        font-size: 28px;
      }
    }
  }
`;
