import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextLink from 'next/link';
import * as assetsConfig from '../../config/assets';

import { Badge } from '../../graphics';
import CurriculumIcon from '../curriculums/CurriculumIcon';
import type { IconName } from '../curriculums/CurriculumIcon';
import CurriculumImage from '../curriculums/CurriculumImage';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_next.svg`}
      className={className}
      onClick={onClick}
      alt="next"
      style={{ width: '48px', height: '48px', zIndex: 2 }}
    />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_prev.svg`}
      className={className}
      onClick={onClick}
      alt="prev"
      style={{ width: '48px', height: '48px', zIndex: 2 }}
    />
  );
}

const CurriculumsCarousel = ({ curriculums }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // centerModeをtrueにすると1にしないといけない
          initialSlide: 2,
          centerMode: true,
          variableWidth: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
                    {/* このスタイルのみここで宣言しないと適応されない */}
                    .slick-initialized .slick-slide {
                        display: flex !important;
                    }
                `}
      </style>
      <Box px={{ xs: 0, sm: 2 }}>
        <StyledSlider {...settings}>
          {curriculums.map((curriculum) => (
            <NextLink key={curriculum.id} href={`/curriculums/${curriculum.slug}/`} passHref>
              <Box
                px={{ xs: 0.5, sm: 1.5 }}
                mb={4}
                key={curriculum.id}
                sx={{ display: 'block', width: '100%', height: 'calc(100% - 47px + 16px)' }}
              >
                <Box sx={{ display: 'column', position: 'relative', zIndex: '-1' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      boxShadow: '0 0 8px #88888833',
                      background: '#fff',
                      height: '5px',
                      margin: '0 15px',
                      borderRadius: '8px',
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: 'relative',
                      boxShadow: '0 0 8px #88888833',
                      background: '#fff',
                      height: '5px',
                      margin: '0 10px',
                      borderRadius: '8px',
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: 'relative',
                      boxShadow: '0 0 8px #88888833',
                      background: '#fff',
                      height: '5px',
                      margin: '0 5px',
                      borderRadius: '8px',
                    }}
                  ></Box>
                </Box>
                {/* borderTopの太さをスマホ版では8pxにする */}
                <Box
                  mb={1}
                  px={1}
                  sx={{
                    height: '100%',
                    borderTop: { xs: '8px solid ' + curriculum.color, md: '10px solid ' + curriculum.color },
                    boxShadow: '0 4px 12px #88888844',
                    background: '#fff',
                    borderRadius: '8px 8px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease-in-out',
                    ':hover': {
                      boxShadow: '0 4px 12px #88888877',
                      marginTop: '-2px',
                    },
                  }}
                >
                  <IconWrapper>
                    <IconBase sx={{ backgroundColor: curriculum.color }}>
                      <CurriculumIcon name={curriculum.icon as IconName} />
                    </IconBase>
                  </IconWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      top: { xs: '0', md: '-16px' },
                      position: 'relative',
                      width: '80%',
                      margin: '16px auto 0px',
                    }}
                  >
                    <CurriculumImage curriculum={curriculum} width={259} height={123} />
                  </Box>
                  <Box>
                    <CardTitle>
                      {curriculum.title.split('の教科書').map((word, i) => (
                        <span key={i}>
                          {i === 1 && 'の教科書'}
                          {word}
                        </span>
                      ))}
                    </CardTitle>
                    {curriculum.badgeType && (
                      <Box>
                        <BadgeWrapper>
                          <BadgeBase className={curriculum.badgeType}>
                            <Badge width={12} height={12} />
                            <span className="text">{curriculum.badgeText}</span>
                          </BadgeBase>
                        </BadgeWrapper>
                      </Box>
                    )}
                  </Box>
                  <Box my={2} sx={{ borderBottom: 1, borderColor: '#DADADA' }}></Box>
                  <CardText>{curriculum.description}</CardText>
                  <Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Typography
                        mt={1}
                        sx={{
                          color: '#555',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '171%',
                          textAlign: 'center',
                          display: 'block',
                        }}
                      >
                        {curriculum.lessonsCount}個のコースがあります
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    my={1}
                    sx={{ position: 'relative', width: '-webkit-fill-available', marginTop: 'auto', bottom: '0px' }}
                  >
                    <NextLink href={`/curriculums/${curriculum.slug}/`} passHref>
                      <Button
                        variant="contained"
                        component="a"
                        size="large"
                        sx={{ backgroundColor: '#FFD900', width: '100%', fontSize: { xs: '12px', md: '16px' } }}
                      >
                        コースを見る
                      </Button>
                    </NextLink>
                  </Box>
                </Box>
              </Box>
            </NextLink>
          ))}
        </StyledSlider>
        <Box
          sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', alignItems: 'center', marginTop: '8px' }}
        >
          <Typography variant="body2" sx={{ fontFamily: 'Inter' }}>
            {currentSlide + 1}/{curriculums.length}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CurriculumsCarousel;

const StyledSlider = styled(Slider)`
  .slick-track {
    height: 100% !important;
    display: flex !important;
    @media (max-width: 600px) {
      width: fit-content !important;
      left: calc((100vw / 2 - 16px) / 2) !important;
    }
  }

  .slick-slide {
    @media (max-width: 600px) {
      width: calc(100vw / 2 - 16px) !important;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #ffd900;
  }

  .slick-dots {
    position: relative !important;
    top: 4px !important;
  }

  .slick-slide {
    height: auto !important;
  }

  .slick-next,
  .slick-prev {
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
  .slick-next {
    right: 0px !important;
  }
  .slick-prev {
    left: 0px !important;
  }
`;

const BadgeWrapper = styled(Box)`
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: center;
`;

const BadgeBase = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 32px;
  font-weight: bold;
  padding: 3px 8px 3px 4px;
  gap: 4px;
  line-height: 1.2;
  svg {
    flex-shrink: 0;
  }
  .text {
    font-weight: bold;
    margin-top: 2px;
  }

  &.officially {
    svg {
      fill: #ffd900;
    }
    .text {
      color: #555;
    }
  }

  &.supervision {
    svg {
      fill: #3e92cc;
    }
    .text {
      color: #3e92cc;
    }
  }
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
`;

const IconBase = styled(Box)`
  position: relative;
  left: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 0 0 16px 16px;
  z-index: 1;
  color: #fff;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const CardTitle = styled.div`
  padding: 8px 24px 0;
  min-height: 48px;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  word-break: break-all;
  color: #555;
  margin-bottom: 8px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  @media (max-width: 899px) {
    min-height: 56px;
    padding: 8px 16px 0;
  }

  > span {
    position: relative;
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    margin: auto 0;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

const CardText = styled.span`
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1.6px;
  font-family: Noto Sans JP;
  text-align: center;
  display: block;
  padding-right: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  @media (max-width: 899px) {
    font-size: 12px;
  }
`;
