import styled from '@emotion/styled';
import { Container, Box, Typography } from '@mui/material';
import type { FC } from 'react';
import * as assetsConfig from '../../config/assets';
import { LogoWhite } from '../../graphics';
import SignupForm from '../forms/SignupForm';

interface Props {
  isLoggedIn: boolean;
}

const BottomEyecatch: FC<Props> = ({ isLoggedIn }) => {
  return (
    <Wrapper>
      <Main>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row-reverse', xs: 'column' },
            padding: { md: '10px 0', xs: '48px 0' },
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              textAlign: { md: 'left', xs: 'center' },
              maxWidth: { md: '60%' },
              paddingTop: { md: 0, xs: '70px' },
            }}
          >
            <Typography component="p" sx={{ fontWeight: '400', fontSize: '18px' }}>
              あなたのお店をもっと繁盛店に！
            </Typography>
            <Typography component="p" sx={{ fontWeight: '700', fontSize: { md: '24px', xs: '20px' } }}>
              仕込みの合間に通うお店の学校
            </Typography>
            <Box sx={{ width: { xs: '236px', md: '377px' }, margin: { md: '10px 0 0 0', xs: '10px auto 0 auto' } }}>
              <LogoWhite />
            </Box>
          </Box>
          {!isLoggedIn && (
            <Box sx={{ flexGrow: 1, padding: { md: '56px 64px 56px 0', xs: '20px 0 0 0' } }}>
              <SignupForm />
            </Box>
          )}
        </Box>
      </Main>
      <BackgroundImage />
    </Wrapper>
  );
};

export default BottomEyecatch;

const Main = styled(Container)`
  z-index: 1;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  background: #000;
  color: #fff;
`;

const BackgroundImage = styled.div`
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('${assetsConfig.prefix}eyecatches/bottom-eyecatch.jpg') no-repeat center top;
  background-size: cover;
  @media (max-width: 900px) {
    background-image: url(${assetsConfig.prefix}eyecatches/bottom-eyecatch-portrait.jpg);
  }
`;
