import { useState, useEffect, memo, useMemo } from 'react';
import type { FC } from 'react';
import styled from '@emotion/styled';
import CurriculumImage from '../curriculums/CurriculumImage';
import type { CurriculumObject } from '../../entities';

export interface Props {
  curriculums: CurriculumObject[];
}

const MemoizedCurriculumImage = memo(CurriculumImage);

const NewLogoAnimation: FC<Props> = ({ curriculums }) => {
  const [windowWidth, setWindowWidth] = useState(0);

  const oneItemsWidth = useMemo(() => {
    return 139 * Math.ceil(curriculums.length / 2);
  }, [curriculums]);

  const filledItemsCount = useMemo(() => {
    return oneItemsWidth === 0 ? 0 : Math.ceil(windowWidth / oneItemsWidth);
  }, [windowWidth, oneItemsWidth]);

  const maxAnimationSize = useMemo(() => {
    return oneItemsWidth * filledItemsCount;
  }, [filledItemsCount, oneItemsWidth]);

  const SliderItems = useMemo(() => {
    if (!isFinite(filledItemsCount)) return null;
    return [...Array(filledItemsCount * 2)].map(() =>
      curriculums.map((curriculum, index) => {
        if (index % 2 === 0) {
          return (
            <div key={curriculum.id}>
              <MemoizedCurriculumImage curriculum={curriculum} width={139} height={65} loading="eager" />
              {curriculums[index + 1] && (
                <MemoizedCurriculumImage curriculum={curriculums[index + 1]} width={139} height={65} loading="eager" />
              )}
            </div>
          );
        }
        return null;
      })
    );
  }, [curriculums, filledItemsCount]);

  useEffect(() => {
    let cullTime = 0;

    if (typeof window === 'undefined') return;

    setWindowWidth(window.innerWidth);

    const onResize = () => {
      window.clearTimeout(cullTime);

      cullTime = window.setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 80);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <StyledSlider width={oneItemsWidth} max={maxAnimationSize} itemsCount={filledItemsCount * 2}>
      {SliderItems}
    </StyledSlider>
  );
};

const StyledSlider = styled.div<{ width: number; max: number; itemsCount: number }>`
  display: flex;
  width: ${({ width, itemsCount }) => width * itemsCount}px;
  animation: slide 26s infinite linear;
  will-change: transform;

  > div {
    display: block;
    width: 139px;
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translate(${({ max }) => -max}px);
    }
  }
`;

export default NewLogoAnimation;
