import { useRouter } from 'next/router';
import type { NextRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';

interface Props {
  lessonId?: string;
  previewAccessToken?: string | null;
  onClose?: () => void;
}

const useLesson = ({ lessonId = '', onClose = () => {}, previewAccessToken }: Props = {}) => {
  const router = useRouter();
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  const { lessonId: currentLessonId } = router.query as { lessonId: string };

  // const link = `${router.asPath.replace(/\?.*/, '')}?lessonId=${lessonId}`;
  const link = useMemo(() => {
    const params = getSearchParams(router.query);
    lessonId !== null && lessonId !== undefined && params.set('lessonId', lessonId);
    const queryString = params.toString();
    if (previewAccessToken) {
      params.set('previewAccessToken', previewAccessToken);
    }
    return `${router.asPath.replace(/\?.*/, '')}${queryString.length > 0 ? `?${queryString}` : ''}`;
  }, [router, lessonId]);

  useEffect(() => {
    if ((!currentLessonId || currentLessonId !== lessonId) && selectedLessonId === lessonId) {
      onClose();
    }
    setSelectedLessonId(currentLessonId);
  }, [currentLessonId, lessonId, onClose, selectedLessonId]);

  const push = (lessonId: string | null) => {
    const params = getSearchParams(router.query);
    params.delete('lessonId');
    lessonId !== null && params.set('lessonId', lessonId);
    const queryString = params.toString();
    const newPath = `${router.asPath.replace(/\?.*/, '')}${queryString.length > 0 ? `?${queryString}` : ''}`;
    if (previewAccessToken) {
      params.set('previewAccessToken', previewAccessToken);
    }
    if (router.asPath !== newPath) {
      // NOTE: コード的には shallow: true にしても動きそうだが、chrome のみ発生する不具合があったため様子 shallow: false にしている
      // https://www.notion.so/movinc/856aaf508f6f4c3cafc0e22568466a12?pvs=4
      router.push(newPath, undefined);
    }
  };

  return { link, currentLessonId, push };
};

function getSearchParams(query: NextRouter['query']): URLSearchParams {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, val]) => {
    val && params.append(key, val.toString());
  });
  return params;
}

export default useLesson;
