import Head from 'next/head';
import { useRouter } from 'next/router';
import type { FC } from 'react';

interface Props {
  title: string;
  description?: string;
  image?: string;
  noindex?: boolean;
}

const defaultDescription = '仕込みの合間に通うお店の学校、口コミアカデミー';
const defaultImage = 'https://academy.kutikomi.com/ogp.png';

const Seo: FC<Props> = ({ title, description, image = defaultImage, noindex }) => {
  const router = useRouter();
  const metaDescription = description || defaultDescription;

  return (
    <Head>
      <title>{`${title || ''}${title && ' - '}口コミアカデミー`}</title>
      <meta name="description" content={metaDescription} />
      {image && <meta property="og:image" content={/^https?/.test(image) ? image : `https:${image}`} />}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      <meta name="canonical" content={`https://academy.kutikomi.com${router.asPath.split('?')[0]}`} />
      {image && <meta name="twitter:image" content={image} />}

      {noindex == true && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default Seo;
