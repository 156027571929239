import Logo from './Logo';
import LogoWhite from './LogoWhite';
import Humburger from './Humburger';
import Close from './Close';
import ChevronRight from './ChevronRight';
import DashedArrow from './DashedArrow';
import CurveBg from './CurveBg';
import ManConcidering from './ManConcidering';
import ManConcideringMobile from './ManConcideringMobile';
import Inbound from './Inbound';
import OTA from './OTA';
import Other from './Other';
import Salon from './Salon';
import Food from './Food';
import Point from './Point';
import QR from './QR';
import SNS from './SNS';
import Map from './Map';
import Badge from './Badge';
import CompleteBadge from './CompleteBadge';
import SignUp from './SignUp';
import Bulb from './Bulb';
import Mail from './Mail';

export {
  //
  Logo,
  Humburger,
  Close,
  ChevronRight,
  LogoWhite,
  DashedArrow,
  CurveBg,
  ManConcidering,
  ManConcideringMobile,
  Inbound,
  OTA,
  Other,
  Salon,
  Food,
  Point,
  QR,
  SNS,
  Map,
  Badge,
  CompleteBadge,
  SignUp,
  Bulb,
  Mail,
};
