import { Box, Fab, Fade, useScrollTrigger } from '@mui/material';
import type { FC } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { useScrollTopButtonPositionContext } from '@/contexts/ScrollTopButtoPositionContext';

const ScrollTop: FC = () => {
  const trigger = useScrollTrigger({
    target: typeof window === 'undefined' ? undefined : window,
    disableHysteresis: true,
    threshold: 100,
  });

  const context = useScrollTopButtonPositionContext();

  const handleClick = () => {
    const anchor = document.getElementsByTagName('div')[0];

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} sx={{ position: 'fixed', bottom: context.bottom, right: context.right, zIndex: 1000 }}>
        <Fab
          size="large"
          aria-label="scroll back to top"
          color="secondary"
          sx={{
            borderRadius: 0,
            background: '#505050',
            width: { xs: '56px', md: '80px' },
            height: { xs: '56px', md: '80px' },
          }}
        >
          <KeyboardArrowUpIcon fontSize="large" sx={{ width: '50%', height: '50%' }} />
        </Fab>
      </Box>
    </Fade>
  );
};

export default ScrollTop;
