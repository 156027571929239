import Cookie from 'js-cookie';
import { isProduction } from './env';

export const firstTouchURLKey = 'kcomFirstTouchURL';
export const firstTouchURLOptions = {
  domain: isProduction ? '.kutikomi.com' : undefined,
  path: '/',
  expires: 30,
};

export const getFirstTouchURL = (): string => {
  return Cookie.get(firstTouchURLKey) || '';
};

export const setFirstTouchURL = (): void => {
  if (!getFirstTouchURL()) {
    Cookie.set(firstTouchURLKey, window.location.href, firstTouchURLOptions);
  }
};

export const lastTouchURLKey = 'kcomLastTouchURL';
export const lastTouchURLOptions = {
  domain: isProduction ? '.kutikomi.com' : undefined,
  path: '/',
  expires: 30,
};

export const getLastTouchURL = (): string => {
  return Cookie.get(lastTouchURLKey) || '';
};

export const setLastTouchURL = (): void => {
  if (getLastTouchURL()) {
    Cookie.remove(lastTouchURLKey, lastTouchURLOptions);
  }
  Cookie.set(lastTouchURLKey, window.location.href, lastTouchURLOptions);
};

const inquiryPopupClosedKey = 'inquiryPopupClosed';
export const getInquiryPopupClosed = (): boolean => {
  return !!Cookie.get(inquiryPopupClosedKey);
};
export const setInquiryPopupClosed = (): void => {
  Cookie.set(inquiryPopupClosedKey, 'true', {
    path: '/',
    expires: 14,
  });
};
