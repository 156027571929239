import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

interface ContextValue {
  isOpen: boolean;
  isCloseShown: boolean;
  title?: string;
  setTitle: (title: string | undefined) => void;
  open: (closeCallback?: () => void) => void;
  close: () => void;
  showClose: () => void;
};
const SignupDialogContext = createContext<ContextValue | null>(null);

export const useSignupDialogContext = () => {
  return useContext(SignupDialogContext);
};

export const useSignDialogContextState = (): ContextValue => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseShown, setIsCloseShown] = useState(false);
  const [closeCallback, setCloseCallback] = useState<(() => void) | undefined>();
  const [title, setTitle] = useState<string | undefined>();

  return {
    isOpen,
    isCloseShown,
    title,
    setTitle,
    open: (closeCallback) => {
      setCloseCallback(() => closeCallback);
      setIsOpen(true);
    },
    close: () => {
      closeCallback?.();
      setIsOpen(false);
      setIsCloseShown(false);
      setCloseCallback(undefined);
    },
    showClose: () => { setIsCloseShown(true); }
  };
};

type Props = {
  children: ReactNode
} & ContextValue;
export const SignupDialogProvider = ({ children, ...rest }: Props) => {
  return <SignupDialogContext.Provider value={rest}>{children}</SignupDialogContext.Provider>;
};
