import type { CSSProperties, MouseEventHandler } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as assetsConfig from '../../config/assets';

export function NextArrow(props: { className?: string; style?: CSSProperties; onClick?: MouseEventHandler }) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_next.svg`}
      className={className}
      onClick={onClick}
      alt="next"
      style={{ ...style, width: '48px', height: '48px', zIndex: 2, top: '50%' }}
    />
  );
}

export function PrevArrow(props: { className?: string; style?: CSSProperties; onClick?: MouseEventHandler }) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_prev.svg`}
      className={className}
      onClick={onClick}
      alt="prev"
      style={{ ...style, width: '48px', height: '48px', zIndex: 2, top: '50%' }}
    />
  );
}
