const phoneNumberRegex = /^0\d{9,10}$/; // 0から始まり、残りの数字9桁または10桁の半角数字
const phoneNumberRegex2 = /^0(\d)\1{8,9}$/; // 01111111111などのゾロ目
const phoneNumberRegex3 = /^(\d)\1{10,11}$/; // 11111111111などのゾロ目
const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const isValidPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.indexOf('--') !== -1) {
    return false;
  }
  const replaceValue = phoneNumber.replace(/-/g, '');
  return (
    phoneNumberRegex.test(replaceValue) &&
    !phoneNumberRegex2.test(replaceValue) &&
    !phoneNumberRegex3.test(replaceValue)
  );
};

export const isValidURL = (url: string) => {
  return urlRegex.test(url);
};
