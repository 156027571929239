import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import type { FC } from 'react';

const TaglineBaloon: FC = () => (
  <BalloonWrapper>
    <Typography variant="h5" sx={{ fontWeight: 700, letterSpacing: '0.1em', mb: 2, lineHeight: '1.8em' }}>
      口コミアカデミーは
      <br />
      口コミサイトの運営や
      <br />
      ノウハウが学べる
      <br />
      店長限定の無料の
      <br />
      オンラインスクールです。
    </Typography>
    <Box sx={{ color: '#555', lineHeight: '1.8em' }}>
      様々な口コミサイトのかしこい運用方法やお店を取り巻く市場やデータ、店舗運営に関する様々なことを時間と場所に縛られず、自分のペースで読んで・聞いて・参加して学ぶことができます。
    </Box>
  </BalloonWrapper>
);

export default TaglineBaloon;

const BalloonWrapper = styled.div`
  border: 2px solid #ffd900;
  border-radius: 4px;
  padding: 40px;
  max-width: 410px;
  position: relative;
  background-color: #ffffffcc;
`;
