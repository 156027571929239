export const pageTitles = {
  top: 'トップページ',
  myPage: 'マイページ',
  lectures: '動画で学ぶ',
  seminars: 'セミナーで学ぶ',
  whitepapers: '店舗マーケティング資料を見る',
  cases: '活用事例で学ぶ',
  curriculums: '教科書で学ぶ',
  news: '記事を読む',
  about: '口コミアカデミーとは',
  contact: 'お問い合わせ',
} as const;

/**
 * @deprecated
 */
export const top = 'トップページ';
/**
 * @deprecated
 */
export const myPage = 'マイページ';
/**
 * @deprecated
 */
export const lectures = '動画で学ぶ';
/**
 * @deprecated
 */
export const seminars = 'セミナーで学ぶ';
/**
 * @deprecated
 */
export const whitepapers = '店舗マーケティング資料を見る';
/**
 * @deprecated
 */
export const cases = '活用事例で学ぶ';
/**
 * @deprecated
 */
export const curriculums = '教科書で学ぶ';
/**
 * @deprecated
 */
export const news = '記事を読む';
/**
 * @deprecated
 */
export const about = '口コミアカデミーとは';
/**
 * @deprecated
 */
export const contact = 'お問い合わせ';
