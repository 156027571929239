import { Box, Button, Dialog, DialogTitle, DialogContent, Typography, CircularProgress } from '@mui/material';
import { useState } from 'react';
import type { FC } from 'react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

interface Props {
  open: boolean;
  onClose?: () => void;
  onNext?: () => void;
  achievePoint?: string;
  sectionInbound?: boolean;
  nextLesson?: string;
}

const LessonAchieveDialog: FC<Props> = ({ open, onClose, onNext, achievePoint, nextLesson, sectionInbound }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const router = useRouter();

  const dialogContent = {
    sectionElementaryThird: {
      title: 'いいペースです！',
      message: 'まずは折り返し地点を目指してどんどん読んでいきましょう！',
      image: 'elementary-third.png',
    },
    sectionElementaryHalf: {
      title: '折り返し地点を過ぎました！',
      message: '初級編も残り半分です。後半も頑張っていきましょう！',
      image: 'elementary-half.png',
    },
    sectionElementaryLast: {
      title: 'お疲れさまでした！',
      message: '初級編が読み終わりました。中級編も頑張っていきましょう！',
      messageLast: '初級編が読み終わりました。現在この教科書は全て読了済みです。',
      image: 'elementary-last.png',
    },
    sectionIntermediateThird: {
      title: 'いいペースです！',
      message: 'まずは折り返し地点を目指してどんどん読んでいきましょう！',
      image: 'intermediate-third.png',
    },
    sectionIntermediateHalf: {
      title: '折り返し地点を過ぎました！',
      message: '中級編も残り半分です。後半も頑張っていきましょう！',
      image: 'intermediate-half.png',
    },
    sectionIntermediateLast: {
      title: 'お疲れさまでした！',
      message: '中級編が読み終わりました。上級編も頑張っていきましょう！',
      messageLast: '中級編が読み終わりました！他のコンテンツも読んでみましょう！',
      messageInbound: '中級編が読み終わりました！次は各県のデータを見てみましょう。',
      image: 'intermediate-last.png',
    },
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };

  const handleImageLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <Dialog open={open} onClick={onClose}>
      {!isLoaded && (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px' }}
        />
      )}
      <Box
        onClick={handleInnerClick}
        sx={{
          background: '#fff',
          p: { xs: '32px 24px 40px', md: '48px 48px 56px' },
          textAlign: 'center',
          opacity: isLoaded ? '1' : '0',
          transition: 'opacity 0.3s ease',
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: { xs: '24px', md: '28px' }, letterSpacing: '0.1em' }}>
          {dialogContent[achievePoint]?.title}
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.1em', mb: '32px' }}>
            {nextLesson
              ? sectionInbound && achievePoint === 'sectionIntermediateLast'
                ? dialogContent[achievePoint]?.messageInbound
                : dialogContent[achievePoint]?.message
              : dialogContent[achievePoint]?.messageLast}
          </Typography>
          <NextImage
            src={`dialog/${dialogContent[achievePoint]?.image}`}
            width={488}
            height={282}
            alt=""
            onLoadingComplete={handleImageLoaded}
          />
          <Box
            sx={{
              mt: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            {nextLesson === null ||
            achievePoint === 'sectionElementaryLast' ||
            (sectionInbound &&
              (achievePoint === 'sectionElementaryLast' || achievePoint === 'sectionIntermediateLast')) ? (
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                size="large"
                sx={{ padding: '15px', width: '100%', maxWidth: '236px' }}
                onClick={() => {
                  router.push('/curriculums/');
                }}
              >
                他のコンテンツを読んでみる
              </Button>
            ) : null}
            {nextLesson ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{ padding: '15px', fontWeight: 'bold', width: '100%', maxWidth: '236px' }}
                onClick={onNext}
              >
                続けて読む
              </Button>
            ) : null}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LessonAchieveDialog;
