import styled from '@emotion/styled';
import type { ComponentProps, FC } from 'react';
import NextLink from 'next/link';
import { Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
  children: React.ReactNode;
  href: string;
  variant?: ComponentProps<typeof Typography>['variant'];
}

const SiteLink: FC<Props> = ({ children, href, variant }) => {
  const isExternal = href.startsWith('http');
  return (
    <Wrapper>
      <NextLink href={href} passHref>
        <Typography
          component="a"
          variant={variant || 'body1'}
          className={isExternal ? 'external' : undefined}
          target={isExternal ? '_blank' : undefined}
        >
          {children}
          {isExternal ? <OpenInNewIcon sx={{ height: '0.5em' }} /> : undefined}
        </Typography>
      </NextLink>
    </Wrapper>
  );
};

export default SiteLink;

const Wrapper = styled.li`
  list-style: none;
  flex: 1 0 33%;
  padding: 0;
  margin: 0;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
`;
