import { Autocomplete, TextField } from '@mui/material';
import type { ComponentProps } from 'react';
import { useEffect, useState, forwardRef } from 'react';
import { getIndustries } from '../../clients/api';
import type { IndustryObject } from '../../entities';

type TextFieldProps = ComponentProps<typeof TextField>;
type AutocompleteProps = ComponentProps<typeof Autocomplete<string, true>>;

interface Props {
  maxValues?: number;
  name: TextFieldProps['name'];
  error: TextFieldProps['error'];
  label: TextFieldProps['label'];
  helperText: TextFieldProps['helperText'];
  fullWidth: TextFieldProps['fullWidth'];
  value: AutocompleteProps['value'];
  onChange: AutocompleteProps['onChange'];
  onBlur: AutocompleteProps['onBlur'];
}

const IndustriesSelect = forwardRef<HTMLDivElement, Props>(function IndustriesSelect(
  { value, onChange, onBlur, maxValues = -1, ...textFieldProps },
  ref
) {
  const [options, setOptions] = useState<IndustryObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const res = await getIndustries();
      setOptions(
        res.sort((a, b) =>
          a.category.index === b.category.index ? a.index - b.index : a.category.index - b.category.index
        )
      );
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Autocomplete<string, true>
      value={value || []}
      multiple
      openOnFocus
      groupBy={(id) => options.find((o) => o.id === id)?.category.name}
      id="select-industries"
      options={options.map(({ id }) => id)}
      getOptionLabel={(id) => options.find((o) => o.id === id)?.name}
      filterSelectedOptions
      limitTags={maxValues}
      onChange={(e, values, ...rest) => {
        if (maxValues !== -1 && values.length > maxValues) {
          e.preventDefault();
          return;
        }
        onChange(e, values, ...rest);
      }}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          ref={ref}
          {...textFieldProps}
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            inputMode: 'none',
          }}
        />
      )}
    />
  );
});

export default IndustriesSelect;
