import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';
import type { FC } from 'react';
import * as assetsConfig from '../../config/assets';
import SignupForm from '../forms/SignupForm';

interface Props {
  isLoggedIn: boolean;
}

const TopEyecatch: FC<Props> = ({ isLoggedIn }) => {
  return (
    <Wrapper>
      <Main>
        <Box
          sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, padding: { md: '10px 0', xs: '48px 0' } }}
        >
          <Box
            sx={{
              padding: '40px 0',
              flexGrow: 1,
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: {
                md: '60%',
              },
            }}
          >
            <Typography component="p" sx={{ fontWeight: '700', fontSize: '18px' }}>
              あなたのお店をもっと繁盛店に！
            </Typography>
            <Typography component="p" sx={{ fontWeight: '700', fontSize: { md: '40px', xs: '32px' } }}>
              仕込みの合間に通う
              <br />
              お店の学校
            </Typography>
          </Box>
          {!isLoggedIn && (
            <Box sx={{ flexGrow: 1, padding: { md: '56px 0', xs: '20px 0 0 0' } }}>
              <SignupForm />
            </Box>
          )}
        </Box>
      </Main>
      <BackgroundImage />
    </Wrapper>
  );
};

export default TopEyecatch;

const Main = styled(Container)`
  z-index: 1;
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  background: #000;
  color: #fff;
`;

const BackgroundImage = styled.div`
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${assetsConfig.prefix}eyecatches/top-eyecatch.jpg) no-repeat center top;
  background-size: cover;
  z-index: 0;
  @media (max-width: 900px) {
    background-position: left top;
  }
  @media (max-width: 675px) {
    background-position: 10% 0;
  }
  @media (max-width: 515px) {
    background-position: 27% 0;
  }
`;
