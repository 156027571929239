import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import type { ReactNode, FC } from 'react';

type Placement = 'x' | 'y';

interface Props {
  children: ReactNode;
  placement?: Placement;
  paddingmini?: string | boolean;
}

const BurstHeadWhite: FC<Props> = ({ children, placement = 'x', paddingmini = false }) => {
  return (
    <Wrapper
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
      placement={placement}
      paddingmini={paddingmini}
    >
      <Typography variant="h6" sx={{ fontSize: { xs: '18px', md: '24px' } }}>
        <span>{children}</span>
      </Typography>
    </Wrapper>
  );
};

export default BurstHeadWhite;

const svg = `
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0H10L22 24L5 0Z" fill="#FFD900"/>
    <path d="M0 9H4.11765L14 24L0 9Z" fill="#3E92CC"/>
  </svg>
`
  .replace(/\n\s*/, '')
  .trim();

const Wrapper = styled(Box)<{ placement: Placement; paddingmini: string | boolean }>`
  margin: 0 auto;
  position: relative;
  h6 {
    > span {
      display: block;
      text-align: center;
      padding: ${({ paddingmini }) => (paddingmini ? '8px 0' : '40px 0')};
    }
    display: flex;
    align-items: stretch;
    font-weight: 700;
    justify-content: center;
    position: relative;
    color: #fff;
    &:before,
    &:after {
      width: 45px;
      content: '';
      display: block;
      background: url('data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}') no-repeat;
      background-position: ${({ placement }) => (placement === 'x' ? 'center center' : 'top center')};
    }
    &:after {
      transform: ${({ placement }) => (placement === 'x' ? 'rotateY(180deg)' : 'rotate(180deg)')};
    }
  }
`;
