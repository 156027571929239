import type { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Container, Grid, Typography } from '@mui/material';
import * as assetsConfig from '../../config/assets';
import BurstHeadWhite from './BurstHeadWhite';

const ThreePossibleThings: FC = () => (
  <Box sx={{ backgroundColor: '#263238', py: { xs: 6, md: 10 } }}>
    <BurstHeadWhite paddingmini="true">完全無料</BurstHeadWhite>
    <Box
      mb={{ xs: 3, md: 5 }}
      sx={{
        fontSize: { xs: '32px', md: '40px' },
        textAlign: 'center',
        fontWeight: 700,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
      }}
    >
      <Box sx={{ display: { xs: 'block', md: 'contents' } }}>口コミアカデミーで</Box>
      <Box sx={{ display: { xs: 'block', md: 'contents' } }}>できる３つのこと</Box>
    </Box>
    <Container>
      <Grid
        container
        spacing={4}
        sx={{ display: 'flex', alignItems: { sx: 'none', md: 'stretch' }, justifyContent: 'center' }}
      >
        <Grid item xs={12} sm={4} sx={{ borderRadius: '16px' }}>
          <Box px={3} py={4} sx={{ backgroundColor: '#fff', borderRadius: '16px', height: '100%' }}>
            <PossibleContentItem>
              <Box mb={3}>
                <Typography variant="h6">
                  <span>01</span>
                </Typography>
                <span className="title">教科書コンテンツが</span>
                <span className="title">読み放題</span>
              </Box>
              <Box mb={3}>
                <img
                  className="point-img"
                  src={`${assetsConfig.prefix + 'top/'}point_img_001.png`}
                  alt="教科書コンテンツ"
                />
              </Box>
              <Box sx={{ color: '#555', fontSize: '16px', lineHeight: '1.8em' }}>
                口コミサイトやGoogleマップを使った集客やインバウンド対策、ポイントシステムなどお店の集客に導く様々なサービスについて運営ノウハウを体系立てて教科書にまとめました。
              </Box>
            </PossibleContentItem>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ borderRadius: '16px' }}>
          <Box px={3} py={4} sx={{ backgroundColor: '#fff', borderRadius: '16px', height: '100%' }}>
            <PossibleContentItem>
              <Box mb={3}>
                <Typography variant="h6">
                  <span>02</span>
                </Typography>
                <span className="title">セミナー / ウェビナー</span>
                <span className="title">への参加お申し込み</span>
              </Box>
              <Box mb={3}>
                <img
                  className="point-img"
                  src={`${assetsConfig.prefix + 'top/'}point_img_002.png`}
                  alt="セミナー / ウェビナー"
                />
              </Box>
              <Box sx={{ color: '#555', fontSize: '16px', lineHeight: '1.8em' }}>
                口コミアカデミーでは店舗運営や口コミサイトの活用方法など様々な専門家や講師の方をお招きして、オンラインでのセミナー授業を実施しております。
              </Box>
            </PossibleContentItem>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ borderRadius: '16px' }}>
          <Box px={3} py={4} sx={{ backgroundColor: '#fff', borderRadius: '16px', height: '100%' }}>
            <PossibleContentItem>
              <Box mb={3}>
                <Typography variant="h6">
                  <span>03</span>
                </Typography>
                <span className="title">調査レポートの</span>
                <span className="title">閲覧</span>
              </Box>
              <Box mb={3}>
                <img
                  className="point-img"
                  src={`${assetsConfig.prefix + 'top/'}point_img_003.png`}
                  alt="調査レポートの閲覧"
                />
              </Box>
              <Box sx={{ color: '#555', fontSize: '16px', lineHeight: '1.8em' }}>
                最新の口コミトレンドや各業種別の口コミ状況など、様々な調査結果をレポートにまとめ、口コミアカデミー会員の方に限り閲覧いただけます。
              </Box>
            </PossibleContentItem>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default ThreePossibleThings;

const PossibleContentItem = styled.div`
  h6 {
    font-size: 40px;
    font-weight: 700;
    color: var(--k-academy-key, #ffd900);
    text-align: center;
    font-family: Inter;
    line-height: 48px;
    letter-spacing: 4px;
    margin-bottom: 16px;
  }
  .title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
  .point-img {
    display: block;
    width: 100%;
  }
`;
