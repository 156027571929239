import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import type { GetServerSideProps, NextPage } from 'next';
import { Raw } from 'typeorm';
import type { CurriculumObject } from '../src/entities';
import { ArticleWhitepaper, ArticleSeminar } from '../src/entities';
import { connectDatabase } from '../src/database';
import * as assetsConfig from '../src/config/assets';
import TopEyecatch from '../src/components/global/TopEyecatch';
import MainLayout from '../src/components/layouts/MainLayout';
import Seo from '../src/components/Seo';
import BurstHead from '../src/components/top/BurstHead';
import DeviceFrame from '../src/components/top/DeviceFrame';
import TaglineBaloon from '../src/components/top/TaglineBaloon';
import { DashedArrow } from '../src/graphics';
import { withUserSsr } from '../src/hooks/session';
import type { UserPageProps } from '../src/types';
import ThreePossibleThings from 'src/components/top/ThreePossibleThings';
import TopThreeCurriculums from 'src/components/top/TopThreeCurriculums';
import LogoAnimation from 'src/components/top/LogoAnimation';
import { findCurriculumsWithLessonCounts } from 'src/helpers/curriculum';
import type { WhitepaperCarouselItem } from '@/components/articles/WhitepaperCarousel';
import type { SeminarCarouselItem } from '@/components/articles/SeminarCarousel';
//import Comments from 'src/components/top/Comments';

interface Props {
  curriculums: CurriculumObject[];
  whitepaperItems: WhitepaperCarouselItem[];
  seminarItems: SeminarCarouselItem[];
}

const Page: NextPage<Props & UserPageProps> = ({ user, curriculums, whitepaperItems, seminarItems }) => {
  return (
    <MainLayout user={user} noContainer>
      <Seo
        title="MEOや口コミサイト活用でもっと店舗集客力アップ"
        description="気になる口コミサイトの登録方法から裏技的な活用法まで「公式監修」！ここでしか読めないノウハウが無料で学び放題のオンラインスクール「口コミアカデミー」"
      />
      <TopEyecatch isLoggedIn={!!user} />
      <Box>
        <ThreePossibleThings />
      </Box>
      <Container>
        <Box my={5} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row-reverse' } }}>
          <Box sx={{ flexGrow: { xs: 0, md: 1 }, display: 'flex', justifyContent: { xs: 'center', md: 'left' } }}>
            <Box
              sx={{
                alignItems: 'center',
                flexDirection: { xs: 'column-reverse', md: 'row' },
                display: 'flex',
                pt: { xs: 4, md: 0 },
              }}
            >
              <BalloonNipple>&nbsp;</BalloonNipple>
              <TaglineBaloon />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', position: 'relative' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}></Box>
            <DeviceFrame videoURL={`${assetsConfig.prefix}preview.mp4`} />
          </Box>
        </Box>
        <BurstHead paddingY="0">各企業公認の教科書を公開中</BurstHead>
      </Container>
      <Box sx={{ py: 3, overflow: 'hidden' }}>
        <LogoAnimation curriculums={curriculums} />
      </Box>
      <Container>
        <Box sx={{ textAlign: 'center', fontWeight: 700, fontSize: { xs: '24px', md: '32px' } }}>
          <Box sx={{ display: { xs: 'block', md: 'contents' } }}>口コミアカデミーで</Box>
          <Box sx={{ display: { xs: 'block', md: 'contents' } }}>勉強してみましょう！</Box>
        </Box>
      </Container>
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', py: 5, mb: '-85px' }}>
        <DashedArrow />
      </Box>
      <Box sx={{ py: { xs: 10, md: 8 } }}>
        <TopThreeCurriculums curriculums={curriculums} whitepaperItems={whitepaperItems} seminarItems={seminarItems} />
      </Box>
      {/* 利用者の声 これは現状公開しないのでコメントアウト */}
      {/*
      <Box>
        <Typography variant="h4" component="p" sx={{ textAlign: 'center', fontWeight: 700, fontSize: '40px', lineHeight: '140%', letterSpacing: '4px' }}>
          利用者の声
        </Typography>
        <Box sx={{ px: { xs: 2, md: 5 }, pt: 3, pb: 4, color: '#555', fontSize: '16px', lineHeight: '1.8em', textAlign: 'center' }}>
          実際に利用したユーザーの声を集めました。
        </Box>
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: { xs: 10, md: 15 } }}>
          <Comments />
        </Container>
      </Box >
      */}
    </MainLayout>
  );
};

export default Page;
export const getServerSideProps: GetServerSideProps<Props> = withUserSsr(async (_context) => {
  await connectDatabase();

  const curriculumsWithCounts = await findCurriculumsWithLessonCounts([{ isHidden: false }]);

  const articleWhitepapers = await ArticleWhitepaper.find({
    relations: ['attachments'],
    where: { isDraft: false, publishedAt: Raw((alias) => `${alias} IS NOT NULL AND ${alias} < NOW()`) },
    order: { publishedAt: 'DESC' },
    take: 15,
  });
  const whitepaperItems = articleWhitepapers
    .map((articleWhitepaper) => {
      const attachments = articleWhitepaper.attachments.map((attachment) => attachment.toJSON());
      const thumbnail = attachments.find((attachment) => attachment.type === 'AttachmentThumbnail');
      if (!thumbnail) {
        return null;
      }
      return {
        whitepaper: articleWhitepaper.toJSON(),
        thumbnail: thumbnail,
      };
    })
    .filter((item) => !!item);

  const articleSeminars = await ArticleSeminar.find({
    relations: ['attachments'],
    where: { isDraft: false, publishedAt: Raw((alias) => `${alias} IS NOT NULL AND ${alias} < NOW()`) },
    order: { publishedAt: 'DESC' },
    take: 15,
  });
  const today = new Date();
  const seminarItems = articleSeminars
    .map((articleSeminar) => {
      const attachments = articleSeminar.attachments.map((attachment) => attachment.toJSON());
      const thumbnail = attachments.find((attachment) => attachment.type === 'AttachmentThumbnail');
      const webinar = attachments.find((attachment) => attachment.type === 'AttachmentWebinar');
      if (!thumbnail || !webinar || new Date(webinar.value.startedAt) < today) {
        return null;
      }
      return {
        seminar: articleSeminar.toJSON(),
        thumbnail: thumbnail,
      };
    })
    .filter((item) => !!item);

  return {
    props: {
      curriculums: curriculumsWithCounts,
      whitepaperItems,
      seminarItems,
    },
  };
});

const BalloonNipple = styled(Box)`
  border-width: 2px 0 0 2px;
  border-style: solid;
  border-color: #ffd900;
  width: 38px;
  height: 38px;
  background-color: #fff;
  position: relative;
  left: 0;
  top: -19px;
  z-index: 100;
  transform: rotate(225deg);
  @media (min-width: 900px) {
    transform: rotate(-45deg);
    left: 19px;
    top: auto;
  }
`;
