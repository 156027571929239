import { createContext, useContext } from 'react';

interface ContextValue {
  right: number;
  bottom: number;
}

const ScrollTopButtonPositionContext = createContext<ContextValue>({ right: 0, bottom: 0 });

export const useScrollTopButtonPositionContext = () => {
  return useContext(ScrollTopButtonPositionContext);
};

export const ScrollTopButtonPositionProvider = ({
  children,
  scrollTopButtonPosition,
}: {
  children: React.ReactNode;
  scrollTopButtonPosition: ContextValue;
}) => (
  <ScrollTopButtonPositionContext.Provider value={scrollTopButtonPosition}>
    {children}
  </ScrollTopButtonPositionContext.Provider>
);
