import NextImage from 'next/image';
import type { ComponentProps, FC } from 'react';
import type { CurriculumObject } from '../../entities/Curriculum';

interface Props {
  curriculum: CurriculumObject;
}

type ImageProps = Omit<ComponentProps<typeof NextImage>, 'src'>;

const CurriculumImage: FC<Props & ImageProps> = ({ curriculum, ...imageProps }) => (
  <NextImage alt={curriculum.title} src={curriculum.logoUrl} {...imageProps} unoptimized />
);

export default CurriculumImage;
