import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useState } from 'react';

type Props = ComponentPropsWithoutRef<typeof TextField>;

const SecretInput = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={showPassword ? 'パスワードを隠す' : 'パスワードを表示する'}
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      ref={ref}
    />
  );
});

SecretInput.displayName = 'SecretInput';

export default SecretInput;
