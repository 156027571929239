import styled from '@emotion/styled';
import { Container, Divider, Grid } from '@mui/material';
import type { FC } from 'react';
import { footerLinks, sitePages } from '../../config/links';
import SiteLink from './SiteLink';

const Footer: FC = () => {
  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ padding: '20px 0 40px 0' }} justifyContent="center" alignItems="center">
          {sitePages.map(([label, href]) => (
            <Grid item key={href}>
              <SiteLink href={href}>{label}</SiteLink>
            </Grid>
          ))}
        </Grid>
        <Divider light />
        <Container maxWidth="lg">
          <Grid container spacing={2} sx={{ padding: '40px 0' }} justifyContent="center" alignItems="center">
            {footerLinks.map(([label, href]) => (
              <Grid item key={href}>
                <SiteLink href={href}>
                  <small>{label}</small>
                </SiteLink>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <Copyright>Copyright &copy; 2022 mov inc.</Copyright>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  background-color: #000;
  color: #fff;
  margin-top: 32px;
`;

const Copyright = styled.p`
  margin: 0 auto;
  padding-bottom: 24px;
  font-size: 0.8rem;
  text-align: center;
`;
