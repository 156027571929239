import { createTheme } from '@mui/material/styles';

export default createTheme({
  shadows: ['none', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F7FA',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F7FA',
          '&.MuiPaginationItem-ellipsis': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#FFD900',
    },
    secondary: {
      main: '#000000',
    },
    info: {
      main: '#3E92CC',
    },
    background: {
      paper: '#f3f3f3',
    },
  },
});
