import type { UserObject } from 'src/entities/User';
import { RegistrationStep } from 'src/types';

export const generateDialogTitle = (user: UserObject | null): string => {
  const REGISTRATION_REQUIRED_MESSAGE = '会員登録が必要です';
  const ADDITIONAL_ANSWERS_REQUIRED_MESSAGE = '追加で回答をいただく必要があります';

  if (!user) {
    return REGISTRATION_REQUIRED_MESSAGE;
  }

  if (isOnlyStep1Completed(user)) {
    return ADDITIONAL_ANSWERS_REQUIRED_MESSAGE;
  }

  if (!user.filledAll) {
    return REGISTRATION_REQUIRED_MESSAGE;
  }

  return '';
};

export const isOnlyStep1Completed = (user: UserObject | null): boolean => {
  return (
    user?.completedRegistrationSteps[0] === RegistrationStep.Basic && user?.completedRegistrationSteps.length === 1
  );
};

export const isMemberRegistrationIncomplete = (user: UserObject | null): boolean => {
  return !user || user?.filledAll === false;
};
